import { strings } from "@/Locales";
import { ShouldRender } from "@/Shared/Components";
import SwitchLanguage from "@/Shared/Components/SwitchLanguage";
import { Avatar } from "@/Shared/Components/ui/avatar";
import { Button } from "@/Shared/Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/Shared/Components/ui/dropdown-menu";
import { Separator } from "@/Shared/Components/ui/separator";
import { Sheet, SheetContent, SheetTrigger } from "@/Shared/Components/ui/sheet";
import authStore from "@/Stores/auth";
import useLanguage from "@/Stores/useLanguage";
import clsx from "clsx";
import { CircleUserRound, Moon, Package2, PanelLeft, Sun } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import Notifications from "./Notifications";
import { useSideNav } from "./useSideNav";

const Header = () => {
  const sidebarNavItems = useSideNav();
  const logout = authStore(store => store.logout);
  const { dir } = useLanguage();
  const [value, setValue] = useLocalStorage("theme", "");
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const toggleDarkMode = () => {
    document.body.classList.toggle("dark");
    setValue(value === "dark" ? "" : "dark");
  };

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <header className="sticky top-0 z-30 flex h-14 mb-6  items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
      <Sheet open={open} onOpenChange={op => setOpen(op)}>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="sm:hidden">
            <PanelLeft className="h-5 w-5" />
            <span className="sr-only">Toggle Menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent className="sm:max-w-xs">
          <nav className="grid gap-6 text-lg font-medium">
            <Link
              to="/"
              className="group mx-3 my-4 flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base">
              <Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
              <span className="sr-only">Barnamjy</span>
            </Link>
            {sidebarNavItems.map(side => (
              <NavLink
                to={side.href}
                key={side.title}
                className={({ isActive }) =>
                  clsx("flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground", {
                    "bg-accent text-accent-foreground": isActive,
                    "text-muted-foreground": !isActive,
                  })
                }>
                {side.Icon}
                {side.title}
              </NavLink>
            ))}
          </nav>
        </SheetContent>
      </Sheet>
      {/* <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to="#">Dashboard</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to="#">Orders</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>Recent Orders</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb> */}
      <div className=" ml-auto flex gap-x-2 items-center">
        <Button className="gap-x-2" variant="ghost" size={"sm"} onClick={() => toggleDarkMode()}>
          <ShouldRender shouldRender={document.body.classList.contains("dark")}>
            <Sun className="h-5 w-5" />
          </ShouldRender>
          <ShouldRender shouldRender={!document.body.classList.contains("dark")}>
            <Moon className="h-5 w-5" />
          </ShouldRender>
        </Button>
        <Separator orientation="vertical" className="h-5" />
        <SwitchLanguage />
        <Separator orientation="vertical" className="h-5" />

        <Notifications />
        <Separator orientation="vertical" className="h-5" />

        <DropdownMenu dir={dir}>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon" className="overflow-hidden rounded-full">
              <Avatar className={clsx("-ms-space-05 flex h-16 w-16  items-center justify-center border bg-card", {})}>
                <CircleUserRound />
              </Avatar>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{strings.Shared.MyAccount}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link to={"/settings"}>{strings.Shared.Settings}</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={logout}>{strings.Shared.Logout}</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};

export default Header;
