import authStore from "@/Stores/auth";

const useAuth = () => {
  const isAuthenticated = authStore(state => state.isAuthenticated);
  const user = authStore(state => state.user);
  const roles = authStore(state => state.roles);
 
  return { isAuthenticated, user, roles };
};

export default useAuth;
