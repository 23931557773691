/* eslint-disable react-hooks/exhaustive-deps */
import { useGetRolesByUserId } from "@/Services/Role";
import authStore from "@/Stores/auth";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../Hooks/useAuth";
import PageLoader from "./PageLoader";

const AuthProvider = () => {
  const location = useLocation();
  const checkAuth = authStore(store => store.checkAuth);
  const updateRoles = authStore(state => state.updateRoles);

  const { isAuthenticated, user } = useAuth();
  const role = useGetRolesByUserId(user?.id);

  useEffect(() => {
    checkAuth();
  }, [location, checkAuth]);

  useEffect(() => {
    if (!role.isLoading && role.data?.length) updateRoles(role.data);
  }, [role]);

  if (!isAuthenticated) return <Navigate to="/login" replace />;

  return role.isLoading ? <PageLoader /> : <Outlet />;
};

export default AuthProvider;
