import { jwtDecode } from "jwt-decode";
import { create } from "zustand";
import { DTO } from "../Models/dto";
import DevtoolsMiddlewares from "./middleware";

interface ExtendedJwtPayload {
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"?: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"?: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"?: string;
  exp?: number;
  // add other claims as needed
}

const authStore = create<DTO.AuthStore>()(
  DevtoolsMiddlewares(
    set => ({
      isAuthenticated: !!localStorage.getItem("token"),
      accessToken: undefined,
      refreshToken: undefined,
      user: undefined,
      roles: undefined,
      screens: [],
      login: (response: DTO.LoginResponse) =>
        set(() => {
          return { isAuthenticated: true, user: response.user, roles: response.roles, screens: response.screens };
        }),
      logout: () =>
        set(() => {
          //  window.location.replace("/");
          localStorage.removeItem("token");
          return { isAuthenticated: false, user: undefined, roles: undefined, screens: undefined };
        }),
      checkAuth: () =>
        set(() => {
          const token = localStorage.getItem("token");
          if (!token) return { isAuthenticated: false, user: undefined };
          const decoded = jwtDecode<ExtendedJwtPayload>(token);
          if (!decoded) return { isAuthenticated: false, user: undefined };

          if (Number(decoded.exp) * 1000 < Date.now()) return { isAuthenticated: false, user: undefined };
          //  const role = useGetRolesByUserId();

          return {
            isAuthenticated: true,
            roles: [],
            user: {
              name: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
              email: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
              id: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
            },
          };
        }),
      updateRoles: (roles?: DTO.Role[]) =>
        set(state => {
          return { ...state, roles };
        }),
    }),
    { name: "auth" }
  )
);

export default authStore;
