export enum StatusCodes {
  OK = 200,
  BadRequest = 400,
  Unauthorized,
  PaymentRequired,
  Forbidden,
  NotFound,
  TooManyRequests = 429,
  InternalServerError = 500,
  BadGateway = 502,
  ServerUnavailable = 503,
}

export enum Gender {
  male = 1,
  female = 2,
}
export enum BoxStatus {
  Active = 1,
  InActive = 2,
}
export enum Period {
  Weekly = 5,
  Montly = 6,
}

export enum MaritalStatus {
  single = 1,
  married = 2,
}

export enum VerificationType {
  email = 1,
  mobile = 2,
}

// export enum Roles {
//   Anonymous = 0,
//   Member,
//   Admin,
//   SuperAdmin,
// }

export enum SupportStatusProcess {
  New = 1,
  InProgress = 2,
  Closed = 3,
  Resolved = 6,
  Cancelled = 7,
  OnHold = 13,
}

export enum PaymentAmountTypes {
  AllSameAmount = 7,
  DifferenetAmount = 8,
}

export enum BoxTypes {
  box = 1,
  saveCircle = 2,
}
export enum PaymentStatus {
  Paid = 3,
  Due = 4,
  Scheduled = 9,
  Withdraw = 10,
  Exempted = 11,
}

export enum PaymentProfileStatus {
  Draft = 1,
  Completed,
}

export enum NotificationSourceType {
  ForgotPssword = 12,
}

export enum Roles {
  SuperAdmin = 1,
  Member,
  Admin,
}
