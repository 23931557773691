import { strings } from "@/Locales";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/Shared/Components/ui/tooltip";
import { useAuth } from "@/Shared/Hooks";
import clsx from "clsx";
import { Package2, Settings } from "lucide-react";
import { NavLink } from "react-router-dom";
import { useSideNav } from "./useSideNav";

function Aside() {
  const sidebarNavItems = useSideNav();
  const { roles } = useAuth();

  return (
    <TooltipProvider>
      <aside className="fixed inset-y-0 start-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
        <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
          <NavLink
            to="/"
            className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base">
            <Package2 className="h-4 w-4 transition-all group-hover:scale-110" />
            <span className="sr-only">{strings.Shared.Barnamjy}</span>
          </NavLink>
          {sidebarNavItems
            .filter(item => roles?.some(role => item?.roles?.includes(role.id)))
            .map(nav => (
              <NavLink
                key={nav.title}
                to={nav.href}
                className={({ isActive }) => {
                  return clsx("flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-foreground md:h-8 md:w-8", {
                    "bg-accent text-accent-foreground": isActive,
                    "text-muted-foreground": !isActive,
                  });
                }}
                end>
                <Tooltip>
                  <TooltipTrigger className="flex">
                    {nav.Icon}
                    <span className="sr-only">{nav.title}</span>
                  </TooltipTrigger>
                  <TooltipContent side="right">{nav.title}</TooltipContent>
                </Tooltip>
              </NavLink>
            ))}
        </nav>
        <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
          <NavLink
            to="settings"
            className={({ isActive }) =>
              clsx("flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-foreground md:h-8 md:w-8", {
                "bg-accent text-accent-foreground": isActive,
                "text-muted-foreground": !isActive,
              })
            }>
            <Settings className="h-5 w-5" />
            <span className="sr-only">Settings</span>
          </NavLink>
        </nav>
      </aside>
    </TooltipProvider>
  );
}

export default Aside;
