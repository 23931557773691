import { strings } from "@/Locales";
import useLanguage from "@/Stores/useLanguage";

// import { Tabs, TabsList, TabsTrigger } from '@@/Core/src/Components/ui/tabs'
import { Globe2 } from "lucide-react";
import { Button } from "./ui/button";

const SwitchLanguage = () => {
  const { changeLang, lang } = useLanguage();
  return (
    <Button className="gap-x-2" variant="ghost" size={"sm"} onClick={() => changeLang(lang === "ar" ? "en" : "ar")}>
      <span>{strings.Shared.SiteLanguageText}</span> <Globe2   />
    </Button>
  );
};
// export const SwitchLanguageMobile = () => {
//     const { changeLang, lang, dir } = useLanguage()
//     return (
//         <Tabs defaultValue={lang} dir={dir} onValueChange={(value: string) => changeLang(value as 'ar' | 'en')}>
//             <TabsList className="w-full">
//                 <TabsTrigger className="grow" value="ar">
//                     العربية
//                 </TabsTrigger>
//                 <TabsTrigger className="grow" value="en">
//                     English
//                 </TabsTrigger>
//             </TabsList>
//         </Tabs>
//     )
// }

export default SwitchLanguage;
