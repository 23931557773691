/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-useless-catch */
import { StatusCodes } from "@/Models/enums";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";
const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true,
  //"X-Requested-With": "XMLHttpRequest",
  // "Accept-Language": "en",
};

const injectToken = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig<any>> => {
  try {
    const token = globalThis.localStorage?.getItem("token");
    if (token != null) {
      const jwt = jwtDecode(token);
      const exp = Number(jwt.exp) * 1000;
      if (exp > Date.now() && (exp - Date.now()) / 60000 < 5) {
        const response = await axios.get<{ token: { token: string; validTo: string } }>(`${config.baseURL}/Authenticate/RefreshLogin`);
        config.headers.token = response.data.token.token;
      } else {
        config.headers.token = `${JSON.parse(token)}`;
      }
    }

    return config;
  } catch (error) {
    throw (error as Error).message;
  }
};

class Http {
  baseURL: string;
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance ?? this.initHttp();
  }
  constructor(baseUrl: string) {
    this.baseURL = baseUrl;
  }
  initHttp() {
    const http = axios.create({
      baseURL: this.baseURL,
      headers,
      // withCredentials: true,
    });

    http.interceptors.request.use(injectToken, error => Promise.reject(error));

    http.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const { response } = error;
        return this.handleError(response);
      }
    );

    this.instance = http;

    return http;
  }

  async refreshToken(): Promise<{ token: string; validTo: string }> {
    const response = await axios.get<{ token: { token: string; validTo: string } }>(`${this.baseURL}/Authenticate/RefreshLogin`);
    return response.data.token;
  }

  request<T = unknown, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = unknown, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = unknown, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // handle generic app errors depending on the status code
  private async handleError(error: AxiosError) {
    if (!error) return;

    const { status } = error;

    switch (status) {
      case StatusCodes.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCodes.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCodes.Unauthorized: {
        // Handle Unauthorized
        if (localStorage.getItem("token")) localStorage.removeItem("token");
        if (window.location.pathname !== "/login") window.location.reload();
        // break;
      }
    }

    return Promise.reject(error);
  }
}

const http = new Http(import.meta.env.VITE_API_BASE_URL);
export default http;
