import { strings } from "@/Locales";
import { useGetNotificationsByUserId } from "@/Services/Notification";
import { ShouldRender } from "@/Shared/Components";
import { Button } from "@/Shared/Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/Shared/Components/ui/dropdown-menu";
import { useAuth } from "@/Shared/Hooks";
import useLanguage from "@/Stores/useLanguage";
import { Bell, BellIcon } from "lucide-react";
import { Fragment } from "react";
const Notifications = () => {
  const { dir } = useLanguage();
  const { user } = useAuth();
  const notif = useGetNotificationsByUserId(user?.id);

  return (
    <DropdownMenu dir={dir}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="overflow-hidden">
          <Bell className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <ShouldRender shouldRender={!notif.isLoading}>
          {!!notif?.data &&
            notif?.data?.map((ntf, indx) => (
              <Fragment key={ntf.id}>
                <DropdownMenuItem>
                  <div className=" w-full flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-accent hover:text-accent-foreground">
                    <BellIcon className="mt-px h-5 w-5" />
                    <div className="space-y-1">
                      <p className="text-sm font-bold leading-none">{strings.Shared[ntf.title]}</p>
                      <p className="text-sm text-muted-foreground max-w-72">
                        {strings.formatString(strings.messages[ntf.title as keyof typeof strings.messages], ntf.content)}
                      </p>
                    </div>
                  </div>
                </DropdownMenuItem>
                <ShouldRender shouldRender={indx !== notif.data.length - 1}>
                  <DropdownMenuSeparator />
                </ShouldRender>
              </Fragment>
            ))}
        </ShouldRender>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Notifications;
