import SecurityImg from "@/Assets/images/security.svg";
import { strings } from "@/Locales";
import { Button } from "@/Shared/Components/ui/button";
import { Link } from "react-router-dom";

const AccessDenied = () => {
  return (
    <div className="col-span-full">
      <div className="flex flex-col items-center justify-center gap-16 lg:mt-28">
        <img src={SecurityImg} alt="SecurityImg" width={400} />
        <div className="space-y-4 flex flex-col justify-center items-center">

        <h1 className="text-xl font-bold">{strings.messages.AccessDenied}</h1>
        <Button asChild>
          <Link
            to="/"
            className="border border-input bg-background hover:bg-accent hover:text-accent-foreground rounded-md text-sm font-medium h-10 px-4 py-2">
            {strings.Shared.BackToHome}
          </Link>
        </Button>
                </div>
      </div>
    </div>
  );
};

export default AccessDenied;
