import { Roles } from "@/Models/enums";
import AccessDenied from "@/Pages/Errors/AccessDenied";
import { PropsWithChildren } from "react";
import useAuth from "../Hooks/useAuth";

const ProtectRoute = ({ ScreenRoles, children }: PropsWithChildren<{ ScreenRoles?: Roles[] }>) => {
  const { roles } = useAuth();

  if (ScreenRoles?.length && !roles?.some(role => ScreenRoles?.includes(role.id))) return <AccessDenied />;

  return children;
};

export default ProtectRoute;
