import { DTO } from "@/Models/dto";
import { useQuery } from "@tanstack/react-query";
import http from "./http";

const endPoint = "Role";

export const useGetRolesByUserId = (userId?: number) =>
  useQuery({
    queryKey: [`roles - ${userId}`],
    queryFn: () => http.get<DTO.Role[]>(`${endPoint}/GetRolesByUserId?userId=${userId}`).then(res => res.data),
    enabled: !!userId,
  });
