import { DTO } from "@/Models/dto";
import { useMutation, useQuery } from "@tanstack/react-query";
import http from "./http";

const endPoint = "Notification";
export const useNotification = () =>
  useMutation({
    mutationKey: ["Notification"],
    mutationFn: (body: DTO.NotificationBody) =>
      http.post<DTO.NotificationBody, DTO.MutateResponse<DTO.NotificationBody>>(`${endPoint}/AddNewNotification`, body).then(res => res.data),
  });

export const useGetNotificationsByUserId = (userId?: number) =>
  useQuery({
    queryKey: ["Notification"],
    queryFn: () => http.get<DTO.NotificationBody[]>(`${endPoint}/GetNotificationsByUserId?userId=${userId}`).then(res => res.data),
    enabled: !!userId,
  });
