/* eslint-disable react-hooks/exhaustive-deps */
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Suspense, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { strings } from "./Locales";
import { PageLoader } from "./Shared/Components";
import useLanguage from "./Stores/useLanguage";

import AppRoutes from "./Pages/route";

const router = createBrowserRouter(AppRoutes);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
      staleTime: 0, //60s
    },
  },
});

function App() {
  const { lang, dir } = useLanguage();

  useEffect(() => {
    if (!lang) return;
    strings.setLanguage(lang);
    document.dir = dir;
  }, [lang, dir]);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<PageLoader />}>
        <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      </Suspense>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
