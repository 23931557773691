import { strings } from "@/Locales";
import { Roles } from "@/Models/enums";
import { Home, LineChart, Package, Users2 } from "lucide-react";
export const useSideNav = () => {
  return [
    {
      title: strings.Shared.Dashboard,
      href: "/",
      Icon: <Home className="h-5 w-5" />,
      roles: [Roles.Member, Roles.Admin, Roles.SuperAdmin],
    },
    // {
    //   title: "Orders",
    //   href: "Orders",
    //   Icon: <ShoppingCart className="h-5 w-5" />,
    // },
    {
      title: strings.Shared.Boxes,
      href: "boxes",
      Icon: <Package className="h-5 w-5" />,
      roles: [Roles.SuperAdmin],
    },
    {
      title: strings.Shared.Members,
      href: "members",
      Icon: <Users2 className="h-5 w-5" />,
      roles: [Roles.SuperAdmin],
    },
    {
      title: strings.Shared.Reports,
      href: "reports",
      Icon: <LineChart className="h-5 w-5" />,
      roles: [Roles.Member, Roles.Admin, Roles.SuperAdmin],
    },
  ];
};
