import { v4 as uuid } from "uuid";
import { Card } from "./ui/card";
import { Skeleton } from "./ui/skeleton";
const items = new Array(6).fill(undefined);
const CardSkeleton = () => {
  return items.map(() => (
    <Card key={uuid()} className="p-6 space-y-2">
      <Skeleton className="h-6 rounded-full   "></Skeleton>
      <div className="flex gap-x-2">
        <Skeleton className="h-6 w-6 rounded-full   "></Skeleton>
        <Skeleton className="h-6 rounded-full w-36  "></Skeleton>
      </div>
      <div className="flex gap-x-2">
        <Skeleton className="h-6 w-6 rounded-full   "></Skeleton>
        <Skeleton className="h-6 rounded-full w-36  "></Skeleton>
      </div>
      <div className="flex gap-x-2">
        <Skeleton className="h-6 w-6 rounded-full   "></Skeleton>
        <Skeleton className="h-6 rounded-full w-36  "></Skeleton>
      </div>
    </Card>
  ));
};

export default CardSkeleton;
