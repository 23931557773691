export const ar = {
  messages: {
    exceedlimitedField: "{0} يجب ان لا يتجاوز {1} حرف",
    required: "{0} اجباري",
    NumberOnly: "رقم مطلوب",
    LoginFailed: "رقم الجوال أو كلمة المرور التي أدخلتها غير صحيحة",
    ForgotPasswordFailed: "رقم الجوال الذي أدخلته غير صحيح",
    InvalidEmail: "البريد الالكتروني غير صحيح",
    InvalidMobile: "رقم الجوال غير صحيح",
    SendSuccess: "تم إرسال الطلب بنجاح",
    EditSuccess: "تم تعديل الطلب بنجاح",
    SendError: "حدث خطأ أثناء إرسال الطلب",
    Confirm: "هل أنت متأكد أنك تريد حذف؟",
    ConfirmVaraint: "هل أنت متأكد أنك تريد {0}؟",
    ConfirmVaraintDesc: "سيؤدي هذا إلى {0} {1} بشكل دائم",
    InvalidStartDate: "تاريخ البداية يجب ان يكون قبل تاريخ النهاية",
    NoResultFound: "لم يتم العثور على نتائج",
    NoResultsFoundDescription: "ستظهر البيانات وتفاصيلها عند ورودها في هذا القسم،",
    Boxdesc: "قائمة بجميع الصناديق بما في ذلك اسمها وتاريخ البدء والمبلغ.",
    Memberdesc: "قائمة بجميع المشتركين بما في ذلك الاسم والبريد الإلكتروني ورقم الجوال.",
    FormDesc: "قم بإجراء التغييرات هنا. انقر فوق حفظ عند الانتهاء",
    MatchPassword: "يجب أن تتطابق كلمات المرور!",
    MatchDownPayemnt: "يجب أن تتطابق مع الدفعة الأولى!",
    PrevPayemntMultipication: "يجب أن تكون الدفعة السابقة من مضاعفات {0} !",
    PaymentProfileDesc: "لعرض المدفوعات، يرجى إكمال ملف المدفوعات",
    PaymentsLimitedExceeded: "يمكنك اختيار 12 دفعة فقط",
    NoWithdrawalsDesc: "لا يوجد سحوبات ، بامكانك اجراء سحب من خلال",
    UndoConfirmTitle: "هل أنت متأكد تماما؟",
    UndoConfirmDescription: "لا يمكن التراجع عن هذا الإجراء. سيؤدي هذا إلى التراجع عن حالة الدفع بشكل دائم.",
    UpdateAccountDescription: "تحديث حسابك. إعادة تعيين كلمة المرور الخاصة بك.",
    UpdateProfileDescription: "حدّث ملفك الشخصي. حدد اسمك المفضل والبريد الإلكتروني ورقم الجوال.",
    BackLogin: "العودة إلى صفحة تسجيل الدخول:",
    ForgotPssword: "لقد طلب المستخدم {0} إعادة تعيين كلمة المرور.",
    AccessDenied: "ليس لديك إذن للوصول إلى هذه الصفحة",
  },
  Shared: {
    Barnamjy: "برنامجي",
    Dashboard: "لوحة المعلومات",
    MyAccount: "حسابي",
    ResetPassword: "إعادة تعيين كلمة المرور",
    Settings: "الإعدادات",
    soon: "قريبًا",
    NationalId: "الهوية الوطنية",
    ResidentID: "الإقامة",
    Welcome: "مرحبًا بك، ",
    Login: "تسجيل الدخول",
    Logout: "تسجيل الخروج",
    UserName: "اسم المستخدم",
    Password: "كلمة المرور",
    ForgotPssword: "نسيت كلمد السر",
    ConfirmPassword: "تأكيد كلمة المرور",
    FrogotPassword: "نسيت كلمة المرور؟",
    SiteLanguageText: "English",
    InformationPanel: "لوحة المعلومات",
    AccountSettings: "إعدادات الحساب",
    Chat: "تواصل معنا",
    TechnicalSupport: "الدعم الفني",
    FullName: "الاسم الكامل",
    PersonalInformation: "المعلومات الشخصية",
    TicketInformation: "معلومات التذكرة",
    TicketDetails: "تفاصيل التذكرة",
    Title: "العنوان",
    Description: "الوصف",
    Enter: "ادخل",
    AddSupportingFiles: "أرفق ملفات داعمة",
    Details: "التفاصيل",
    Send: "إرسال",
    Save: "حفظ",
    Submit: "إرسال الطلب",
    Cancel: "إلغاء",
    Main: "الرئيسية",
    Select: "اختر",
    NotRobot: "الرجاء التحقق",
    Search: "بحث",
    Number: "الرقم",
    Date: "التاريخ",
    hijriCalendar: "التاريخ الهجري",
    male: "ذكر",
    female: "انثى",
    submit: "ارسال",
    next: "التالي",
    previous: "السابق",
    morePages: "المزيد",
    Total: "الإجمالي",
    TotalPaidAmount: "إجمالي المبالغ المدفوعة",
    TotalDueAmount: "إجمالي المبالغ المستحقة",
    TotalWithdrawnAmount: "إجمالي المبالغ المسحوبه",
    TotalBalance: "الرصيد الاجمالي",
    Withdraw: "سحب",
    Withdrawals: "المسحوبات",
    YearTitle: "السنة",
    MonthTitle: "الشهر",
    PaginationDesc: "عرض {0} إلى {1} من أصل {2} نتيجة",
    LinesPerPage: "عدد السطور بالصفحة",
    Actions: "الإجراءات",
    ViewDetails: "عرض التفاصيل",
    Language: "اللغة",
    Specify: "حدد",
    ServerError: "عفوًا، حدث خطأ ما",
    BackToHome: "العودة للصفحة الرئيسية",
    Error: "خطأ",
    Success: "نجاح",
    Email: "البريد الالكتروني",
    Mobile: "رقم الجوال",
    MobileNumber: "رقم الجوال",
    Admin: "مسؤول",
    Edit: "تعديل",
    Delete: "حذف",
    Users: "المستخدمين",
    User: "مستخدم",
    Member: "مشترك",
    MemberName: "اسم المشترك",
    Members: "المشتركين",
    Add: "إضافة",
    Boxes: "الصناديق",
    MyBoxes: "صناديقي",
    Box: "صندوق",
    BoxName: "اسم الصندوق",
    Continue: "الاستمرار",
    Enable: "تفعيل",
    Disable: "تعطيل",
    Disabled: "معطل",
    Name: "الأسم",
    Amount: "المبلغ",
    PreviousBalance: "رصيد سابق",
    DownPayment: "الدفعة الاولى",
    PaymentAmountType: "نوع قيمة الدفعة",
    Fixed: "ثابتة",
    Varaible: "متغيرة",
    StartDate: "تاريخ البداية",
    EndDate: "تاريخ النهاية",
    Period: "نوع الدفعة",
    Status: "الحالة",
    Active: "نشط",
    InActive: "غير نشط",
    SavingCircle: "جمعية",
    New: "جديد",
    NewF: "{0} جديد",
    Create: "إنشاء",
    Monthly: "شهري",
    Weekly: "اسبوعي",
    Week: "اسبوع",
    datePlaceHolder: "اختر تاريخ",
    Manage: "إدارة",
    JOD: "دينار",
    Participants: "المشاركين",
    AddParticipants: "إضافة مشاركين",
    Steps: "خطوة {0} من {1}",
    ChooseParticipants: "اختيار المشاركين",
    ChoosedminMember: "اختيار مدير / مدراء",
    Payments: "المدفوعات",
    PaymentMethod: "طريقة السداد",
    PaymentSchedule: "جدول السداد",
    PaymentTotal: "إجمالي السداد",
    DueTotal: "إجمالي المستحق",
    NextPayment: "الدفعة التالية",
    PaymentDate: "تاريخ الدفعة",
    PaymentStatus: "حالة الدفعة",
    ManageMontlyPaymentsDesc: "إدارة المدفوعات الشهرية لكل مشترك",
    ManageWeeklyPaymentsDesc: "إدارة المدفوعات الاسبوعية لكل مشترك",
    BoxMembers: "مشتركي الصندوق",
    BoxMembersDesc: "إدارة مشتركي الصندوق",
    SelectMemeberPayment: "حدد المشترك لإظهار تفاصيل الدفعات",
    SelectMember: "اختر المشترك",
    Paid: "مدفوعة",
    Due: "مستحقة",
    Scheduled: "مجدولة",
    Exempted: "معفى",
    UpdateAccount: "تحديث الحساب",
    UpdateProfile: "تحديث الملف الشخصي",
    SettingsDesc: "إدارة إعدادات حسابك.",
    Profile: "الملف الشخصي",
    Account: "الحساب",
    Reports: "التقارير",
    BoxTransaction: "حركات الصندوق",
    BoxReport: "تقرير بالصندوق",
    MemberReport: "تقرير بالمشترك",
    WithdrawalReport: "تقرير المسحوبات",
    ReportsDesc: "استعراض تقارير بحسب محددات ومعايير محددة",
    Selected: "محدد",
    FromDate: "من تاريخ",
    ToDate: "الى تاريخ",
    SearchCreteria: "محددات البحث",
    TransactionDate: "تاريخ الحركة",
    PaymentsProfile: "ملف الدفعات",
    PaymentsProfileFor: "ملف الدفعات لـ {0}",
    joiningDate: "تاريخ الانضمام",
    NewWithdrawal: "سحب جديد",
    WithdrawalDate: "تاريخ السحب",
    Note: "ملاحظة",
    Asc: "تصاعدي",
    Desc: "تنازلي",
    Export: "تصدير",
  },

  Footer: {
    TermsOfUse: "شروط الاستخدام",
    PrivacyPolicy: "سياسة الخصوصية",
    InformationProtection: "حماية البيانات",
    Colors: "تباين الألوان",
    VoiceCommands: "الأوامر الصوتية",
    CopyRights: "جميع الحقوق محفوظة ",
  },
  Authentication: {
    LoginDesc: "أدخل رقم جوالك أدناه لتسجيل الدخول إلى حسابك",
    ForgetPasswordDesc: "أدخل رقم جوالك أدناه لإرسال إشعار إلى المشرف لإعادة تعيين كلمة المرور الخاصة بك",
  },
};
