import { create } from 'zustand'
import { DTO } from '../Models/dto'
import DevtoolsMiddlewares from './middleware'

const useLanguage = create<DTO.useLanguageStore>()(
    DevtoolsMiddlewares(
        set => ({
            lang: localStorage.getItem('lang') ?? 'ar',
            dir: localStorage.getItem('lang') === 'en' ? 'ltr' : 'rtl',
            changeLang: value =>
                set(state => {
                    if (value === state.lang) return
                    localStorage.setItem('lang', value)
                    window.location.reload()
                }),
        }),
        { name: 'lanugage' },
    ),
)

export default useLanguage
