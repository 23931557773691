import { RouteObject } from "react-router-dom";

import { Roles } from "@/Models/enums";
import { AuthProvider } from "@/Shared/Components";
import { lazyRetry } from "@/Shared/Components/lazyRetry";
import ProtectRoute from "@/Shared/Components/ProtectRoute";
import Layouts from "./Layouts";

const ErrorPage = lazyRetry(() => import("./Errors/Error"));
const Login = lazyRetry(() => import("./login"));
const ForgotPassword = lazyRetry(() => import("./forgotPassword"));
const Dashbaord = lazyRetry(() => import("./dashboard"));
const Members = lazyRetry(() => import("./Members"));
const Boxes = lazyRetry(() => import("./Boxes"));
const Payments = lazyRetry(() => import("./Payments/Manage"));
const MyPayment = lazyRetry(() => import("./Payments/MyPaymant"));
const SettingsProfilePage = lazyRetry(() => import("./Settings/profile"));
const SettingsLayout = lazyRetry(() => import("./Settings/layout"));
const SettingsAccount = lazyRetry(() => import("./Settings/account"));
const SettingsAppearance = lazyRetry(() => import("./Settings/appearance"));
const ReportsLayout = lazyRetry(() => import("./reports/layout"));
const BoxReport = lazyRetry(() => import("./reports/Box"));
const MemberReport = lazyRetry(() => import("./reports/Member"));
const BoxTransaction = lazyRetry(() => import("./reports/Box/Transaction"));
const WithdrawalReport = lazyRetry(() => import("./reports/Box/Withdrawals"));
const NotFound = lazyRetry(() => import("./Errors/NotFound"));

const AppRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AuthProvider />,

    children: [
      {
        element: <Layouts />,
        children: [
          {
            index: true,
            element: <Dashbaord />,
          },
          {
            path: "members",
            element: (
              <ProtectRoute ScreenRoles={[Roles.SuperAdmin]}>
                <Members />
              </ProtectRoute>
            ),
          },

          {
            path: "boxes",
            element: (
              <ProtectRoute ScreenRoles={[Roles.SuperAdmin]}>
                <Boxes />
              </ProtectRoute>
            ),
          },
          {
            path: "payments",
            children: [
              {
                path: ":id",
                element: (
                  <ProtectRoute ScreenRoles={[Roles.SuperAdmin, Roles.Admin]}>
                    <Payments />
                  </ProtectRoute>
                ),
              },
              {
                path: "mypayment",
                children: [
                  {
                    path: ":id",
                    element: <MyPayment />,
                  },
                ],
              },
            ],
          },
          {
            path: "settings",
            element: <SettingsLayout />,
            children: [
              {
                index: true,
                element: <SettingsProfilePage />,
              },
              {
                path: "account",
                element: <SettingsAccount />,
              },
              {
                path: "appearance",
                element: <SettingsAppearance />,
              },
            ],
          },
          {
            path: "reports",
            element: <ReportsLayout />,
            children: [
              {
                index: true,
                element: <MemberReport />,
              },
              {
                path: "boxReport",
                element: (
                  <ProtectRoute ScreenRoles={[Roles.SuperAdmin, Roles.Admin]}>
                    <BoxReport />
                  </ProtectRoute>
                ),
              },
              {
                path: "boxtransaction",
                element: (
                  <ProtectRoute ScreenRoles={[Roles.SuperAdmin, Roles.Admin]}>
                    <BoxTransaction />
                  </ProtectRoute>
                ),
              },
              {
                path: "WithdrawalReport",
                element: (
                  <ProtectRoute ScreenRoles={[Roles.SuperAdmin, Roles.Admin]}>
                    <WithdrawalReport />
                  </ProtectRoute>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/error/*",
    element: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default AppRoutes;
